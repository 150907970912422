.register {
	overflow-x: hidden;
	background: url('../images/register-background.png') no-repeat center center;
	background-size: cover;

	.moosend-main-form-wrapper>div {
		margin: 0 !important;
	}

	.moosend-main-form-wrapper .form-input-wrapper-element .checkbox-wrapper .form-input-checkbox-element.form-input-checkbox-element {
		padding: 11px !important;
	}

	/* MooSend Error with multiple messages */
	.form-input-error-message+.form-input-error-message {
		display: none !important;
	}

	.text-component {
		padding-block-end: 20px !important;
	}

	.moosend-designer-button {
		background: $gradient-background;
		font-size: 15px;
		line-height: 1.07;
		box-shadow: 0 4px 8px 0 rgba(230, 46, 76, 0.32);
		border: none;
		height: auto;
		padding: 19px 32px !important;
		width: auto !important;
		transition: box-shadow 0.4s ease-in-out;

		&:hover {
			box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
		}
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		width: 100%;
	}

	&:before {
		border-top: 30px solid $black;
		border-right: 200vh solid transparent;
		top: 0;
	}

	&:after {
		border-bottom: 30px solid $black;
		border-right: 200vh solid transparent;
		bottom: 0;
	}

	&__title {
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke: 2px $primary;
		letter-spacing: 3.6px;
		line-height: 1.2;
		text-shadow: 0 0 24px rgba(26, 133, 255, 0.8);

		@include mobile {
			text-shadow: none;
		}
	}

	&__subtitle {
		line-height: 1.1;
	}

	&__form-container {
		border-radius: 6px;
		max-width: 364px;
		width: 100%;
		box-shadow: 0 10px 48px 0 rgba(4, 21, 41, 0.48);

		h3 {
			letter-spacing: 0.4px;
			line-height: 1.2;
		}

		label,
		input {
			font-size: 16px;
			font-family: $family-secondary;
			line-height: 1.5;
			height: auto;
		}

		&-label--terms {
			padding-inline-start: 25px;
			cursor: pointer;

			&:before {
				content: "";
				width: 20px;
				height: 20px;
				border: 1px solid $gray;
				border-radius: 6px;
				position: absolute;
				left: 0;
				top: 1px;
			}
		}

		#terms:checked+&-label--terms:before {
			background: $gradient-background;
		}

		@include mobile {
			margin: 0 auto;
		}
	}

	@include mobile {
		.column {
			text-align: center;
		}
	}
}