@import "../../node_modules/bulma/sass/utilities/initial-variables";

/* overwrite Bulma Variables */
// see https://bulma.io/documentation/customize/variables/
$text-strong: $black;
$size-1: 80px;
$size-2: 60px;
$size-3: 40px;
$size-4: 33px;
$size-5: 27px;
$size-6: 23px;
$size-7: 20px;
$family-primary: 'Fira Sans',
sans-serif;
$family-secondary: 'Open Sans',
sans-serif;
$primary: #3392ff;
$red: #e62e4c;
$black: #020b14;
$gray: #707070;
$lighter: rgba(255, 255, 255, 0.5);
$gap: 20px;
$spacing-values: ("0": 0, "1": 8px, "2": 12px, "3":16px, "4": 20px, "5": 32px, "6": 48px, "7": 64px, "section-spacing":120px);
$button-color: $primary;
$button-border-color: $primary;
$button-background-color: transparent;
$button-hover-color: $primary;
$button-focus-color: $primary;
$button-active-color: $primary;
$button-hover-border-color: $primary;
$button-focus-border-color: $primary;
$button-active-border-color: $primary;
$gradient-background: linear-gradient(108deg, rgb(230, 46, 76), rgb(138, 46, 230));
$gradient-background-hover: linear-gradient(108deg, rgb(138, 46, 230) rgb(230, 46, 76) 100%);

@import "../../node_modules/bulma/bulma";

/*start custom SCSS */
.container {
	max-width: 1172px !important;
}

.py-section-spacing {
	@include mobile {
		padding-block-start: 60px !important;
		padding-block-end: 60px !important;
	}
}

.button {
	&.button--gradient {
		background: $gradient-background;
		font-size: 15px;
		line-height: 1.07;
		box-shadow: 0 4px 8px 0 rgba(230, 46, 76, 0.32);
		border: none;
		height: auto;
		transition: box-shadow 0.4s ease-in-out;

		&:hover {
			color: #fff !important;
			box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
		}
	}

	&.button--reverse-shadow {
		box-shadow: 0 4px 8px 0 rgba(51, 146, 255, 0.32);

		&:hover {
			box-shadow: 0 4px 12px 0 rgba(51, 146, 255, 0.5);
		}
	}
}


@import 'hero';
@import 'highlights';
@import 'skew-right';
@import 'skew-left';
@import 'speakers';
@import 'ecommerce';
@import 'sponsors';
@import 'social';
@import 'register';
@import 'privacy';