.sponsors {
	.column {
		@include desktop {
			padding-block-start: 0;
			padding-block-end: 0;
		}

		@include tablet {
			padding-block-start: 20px;
			padding-block-end: 20px;
		}
		@include mobile {
			padding-block-start: 20px;
			padding-block-end: 20px;
		}
	}
}