.social {
	background-image: radial-gradient(circle at center, #1a85ff -100px, #020b14 469px);
	background-position: bottom -582px center;
	background-repeat: no-repeat;
	background-size: 100% 969px;

	&__time {
		max-width: 470px;
		width: 100%;
		border: 1px solid $primary;
		margin: 0 auto;
		box-shadow: 0 8px 32px 0 rgba(26, 133, 255, 0.5);
		border-radius: 6px;

		&-text {
			-webkit-text-stroke: 2px #3392ff;
			text-shadow: 0 0 24px rgba(26, 133, 255, 0.8);
			letter-spacing: 3.6px;
			color: transparent;
			line-height: 1.1;

			@include mobile {
				text-shadow: none;
			}
		}

		&-divider {
			border-top: 1px solid #1a85ff;
			transform: skew(7deg, 1deg);
		}

		&-period {
			@include until(480px) {
				font-size: 18px !important;
			}
		}
	}

	&__share {
		color: rgba(255, 255, 255, 0.8);
	}

	&__items {
		.column {
			flex-basis: auto;
		}
	}

	&__contact {
		.column {
			flex-basis: auto;
			color: rgba(255, 255, 255, 0.5);

			a {
				color: rgba(255, 255, 255, 0.5);
				word-wrap: break-word;
			}

			@include mobile {
				text-align: center;
			}
		}

	}
}