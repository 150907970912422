.hero {
	.title {
		letter-spacing: 3.2px;
	}

	.subtitle {
		letter-spacing: 2.1px;
	}

	&__copyright {
		position: absolute;
		bottom: 0;
		right: 0;
		letter-spacing: normal;
		font-size: 15px;

		& span {
			text-transform: none;
		}
	}

	&-body {
		background-image: linear-gradient(to bottom, rgba(0, 48, 102, 0), #020b14 98%), linear-gradient(to top, rgba(0, 48, 102, 0), #003066), url('../images/banner-vortex.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__logo {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&__countdown {
		bottom: 10px;
		left: 10px;
		right: 10px;
		border-radius: 6px;
		padding: 11px 24px;
		z-index: 5;
		justify-content: space-between;
		position: fixed;

		&-text-date {
			color: $lighter;
			line-height: 1;
		}

		&-text {
			display: none;

			@include desktop {
				display: block;
			}
		}

		&-timer {
			display: none !important;
			padding: 10px 0;

			&.js--show {
				display: flex !important;
			}

			&-item {
				margin: 0 5px;
			}

			&-label {
				position: absolute;
				left: 6px;
				top: 3px;
				font-size: 12px;
				line-height: 1;
				font-family: $family-secondary;
				color: $lighter;

				&--minutes {
					left: 3px;
				}

				&--seconds {
					left: 8px;
				}

				@include desktop {
					font-size: 15px;
					top: 8px;
				}
			}

			&-value {
				font-size: 32px;
				font-family: $family-secondary;
				-webkit-text-stroke: 2px #fff;
				text-shadow: 0 0 24px rgba(23, 129, 251, 0.4);
				color: transparent;
				line-height: 1;

				@include tablet {
					font-size: 50px;
				}

				@include mobile {
					text-shadow: none;
				}
			}

			@include desktop {
				padding: 0;
			}

			@include until(530px) {
				width: 100%;
				justify-content: center;
			}
		}

		@include until(530px) {
			justify-content: center;
		}

		@include desktop {
			padding: 11px 7px 11px 24px;
		}
	}

	@media (max-height: 890px) {
		&__logo {
			width: 100px;
		}
	}
}