.highlights {
	&__value {
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke: 2px $primary;
		text-shadow: 0 0 24px rgba(26, 133, 255, 0.8);
		letter-spacing: 3.6px;
		line-height: 1.2;

		@include mobile {
			text-shadow: none;
		}
	}

	.column {
		@include tablet {
			padding-block-start: 0;
			padding-block-end: 0;
		}

		@include mobile {
			padding-block-start: 20px;
			padding-block-end: 20px;
		}
	}
}