.speakers {
	&__inner {
		height: 100%;
		background-size: auto 270px;
		background-position: top center;
		background-repeat: no-repeat;
		height: 286px;
		border-radius: 6px;

		&[data-name="Jimmy Kim"] {
			background-color: #072A51;
			background-size: auto auto;
		}
	}

	&__title,
	&__company {
		font-size: 15px;
	}

	@include until(480px) {
		.column {
			width: 100%;
		}
	}
}